<template>
  <div
    :class="{
      'slider__car--block--blue': returnColor === '',
      'slider__car--block--bordo': returnColor === '#801C62',
    }"
    v-if="mainOffer[0] && mainOffer[0].forYouPrice">
    <p class="slider__car--font">
      {{ $t("offerForYou") }}
    </p>
    <div class="slider__car--description">
      <p v-if="mainOffer[0] && mainOffer[0].forYouPrice" class="slider__car--price">
        {{ formatPrice(mainOffer[0].forYouPrice, false) }} zł
        <span class="slider__car--font">brutto</span>
      </p>
    </div>
  </div>
  <div class="slider__car--block" v-if="mainOffer[0] && mainOffer[0].catalogPrice && mainOffer[0].catalogPrice > mainOffer[0].forYouPrice">
    <p class="slider__car--font">
      {{ $t("catalogPrice") }}
    </p>
    <div class="slider__car--description">
      <p v-if="mainOffer[0] && mainOffer[0].catalogPrice && mainOffer[0].catalogPrice > mainOffer[0].forYouPrice" class="slider__car--price">
        {{ formatPrice(mainOffer[0].catalogPrice, false) }} zł
        <span class="slider__car--font">brutto</span>
      </p>
    </div>
  </div>
</template>

<script>
import { useStore } from "../../store";
import { formatPrice } from "../../plugins/helpers";

export default {
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
    returnColor() {
      return useStore().getColor;
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
